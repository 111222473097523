export default class NavigationMobile {
  constructor($el) {
    this.$el = $el;
    this.$mainLinks = this.$el.querySelectorAll('.navigation__link--main');
    this.$backLinks = this.$el.querySelectorAll('.navigation__link--back');

    this.options = {
      submenuClass: 'navigation__level',
      submenuOpenClass: 'navigation__level--open',
      mainItemClass: 'navigation__item--main',
      mainLinkClass: 'navigation__link--main',
      backLinkClass: 'navigation__link--back',
    };
  }

  init() {
    this.$mainLinks.forEach(($link) => {
      $link.addEventListener('click', this.open);
      $link.addEventListener('focusin', this.close);
    });

    this.$backLinks.forEach(($link) => {
      $link.addEventListener('click', this.back);
    });
  }

  deInit() {
    this.$mainLinks.forEach(($link) => {
      $link.removeEventListener('click', this.open);
      $link.removeEventListener('focusin', this.close);
    });

    this.$backLinks.forEach(($link) => {
      $link.removeEventListener('click', this.back);
    });
  }

  open = (event) => {
    const $target = event.currentTarget;
    if ($target) {
      const controls = $target.getAttribute('aria-controls');
      const $submenu = document.getElementById(controls);
      if ($submenu) {
        event.preventDefault();
        const $submenuBackLink = $submenu.querySelector(`.${this.options.backLinkClass}`);

        $submenu.classList.add(this.options.submenuOpenClass);
        $target.setAttribute('aria-expanded', 'true');

        this.timer = setTimeout(() => {
          $submenuBackLink.focus();
        }, 100);
      }
    }
  }

  back = (event) => {
    const $target = event.currentTarget;
    if ($target) {
      event.preventDefault();

      const $targetMainLink = $target.closest(`.${this.options.mainItemClass}`).querySelector(`.${this.options.mainLinkClass}`);
      $targetMainLink.setAttribute('aria-expanded', 'false');
      this.close();
      this.timer = setTimeout(() => {
        $targetMainLink.focus();
      }, 100);
    }
  }

  close = () => {
    this.$el
      .querySelectorAll(`.${this.options.submenuOpenClass}`)
      .forEach(($sub) => {
        $sub.classList.remove(this.options.submenuOpenClass);
      });
  };
}
