import { BREAKPOINTS } from 'javascripts/constants/index';
import NavigationDesktop from './navigation-desktop';
import NavigationMobile from './navigation-mobile';
import NavigationToggle from './navigation-toggle';

const mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);
const $navigationToggle = document.getElementById('navigation-toggle-button');
const $navigation = document.getElementById('navigation');

if ($navigationToggle) {
  const navigationToggle = new NavigationToggle($navigationToggle);

  // Listener
  const onMediaQueryChange = () => {
    navigationToggle.init();
  };

  // Listen on media query changes
  mql.addEventListener('change', onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
}

if ($navigation) {
  $navigation.classList.add('js-navigation-initialized');
  const navigationMobile = new NavigationMobile($navigation);
  const navigationDesktop = new NavigationDesktop($navigation);

  // Listener
  const onMediaQueryChange = (mq) => {
    const isDesktop = mq.matches;
    if (isDesktop) {
      navigationMobile.deInit();
      navigationDesktop.init();
    } else {
      navigationMobile.init();
      navigationDesktop.deInit();
    }
  };

  // Listen on media query changes
  mql.addListener(onMediaQueryChange);

  // First run
  onMediaQueryChange(mql);
}
