import tippy from 'tippy.js';

document.querySelectorAll('.js-tooltip').forEach(($target) => {
  tippy($target, {
    content(reference) {
      const id = reference.getAttribute('data-template');
      const template = document.getElementById(id);
      return template.innerHTML;
    },
    allowHTML: true,
    theme: 'light-border',
  });
});
