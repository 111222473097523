import { BREAKPOINTS } from 'javascripts/constants';
import { createFocusTrap } from 'focus-trap';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class NavigationToggle {
  constructor($toggleButton, options = {}) {
    this.options = {
      mainBlock: 'navigation__items',
      panelClass: 'navigation__item--level',
      ...options,
    };
    this.$toggleButton = $toggleButton;
    this.$panel = document.getElementById(this.$toggleButton.getAttribute('aria-controls'));
    this.$navigationMainBlock = this.$panel.querySelector(`.${this.options.mainBlock}`);
    this.$toggleButtonText = this.$toggleButton.querySelector('.header__navigation__text');
    this.$navigationToggle = document.getElementById('navigation-toggle-button');
    this.$navigation = document.getElementById('navigation');
    this.mql = window.matchMedia(`(min-width: ${BREAKPOINTS.xl})`);

    // Binded events
    this.bindedClick = this.toggle.bind(this);
    this.bindedPanelClick = this.panelClick.bind(this);
    this.onKeydownBinded = this.onKeydown.bind(this);

    this.$toggleButton.addEventListener('click', this.bindedClick);
  }

  init() {
    // Init focus trap
    let focusTrapTarget = this.$panel;

    if (this.mql.matches) {
      focusTrapTarget = this.$panel.querySelector(`.${this.options.panelClass}`);
    }

    this.focusTrap = createFocusTrap(focusTrapTarget, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
      returnFocusOnDeactivate: false,
    });
  }

  toggle(event) {
    event.preventDefault();

    // Get current state of panel
    const isClosed = this.$toggleButton.getAttribute('aria-expanded') !== 'true';

    // Switch state
    if (isClosed) {
      this.openPanel();
    } else {
      this.closePanel();
    }

    // Toggle nav button
    this.$navigationToggle.classList.toggle('active');
    this.$navigation.classList.toggle('active');
  }

  onKeydown(event) {
    // Close menu on ESC
    if (event.keyCode === 27) {
      event.preventDefault();
      this.closePanel();

      // Remove .active from nav button
      this.$navigationToggle.classList.remove('active');
      this.$navigation.classList.remove('active');
    }
  }

  panelClick(event) {
    if (event.target.closest('.navigation')) {
      return;
    }

    this.closePanel();
  }

  closePanel() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'false');

    // Toggle button
    this.$toggleButtonText.innerHTML = 'Menü geschlossen';

    // Close Search Panel
    this.$panel.classList.remove('header__navigation--open');

    // Remove keydown from body
    document.body.removeEventListener('keydown', this.onKeydownBinded);
    this.$panel.removeEventListener('click', this.bindedPanelClick);

    // Disable focus trap
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    // Re-enable scrolling
    enableBodyScroll(this.$panel);
  }

  openPanel() {
    // Change toggle button
    this.$toggleButton.setAttribute('aria-expanded', 'true');

    // Toggle button
    this.$toggleButtonText.innerHTML = 'Menü geöffnet';

    // Show search panel
    this.$panel.classList.add('header__navigation--open');

    // Add keydown to body
    document.body.addEventListener('keydown', this.onKeydownBinded);
    this.$panel.addEventListener('click', this.bindedPanelClick);

    // Disable scrolling
    disableBodyScroll(this.$panel);

    // Enable focus trap
    this.focusTrap.activate();
  }
}
