import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { createFocusTrap } from 'focus-trap';
import Slider from 'javascripts/globals/slider';
import addOverlayLink from 'javascripts/utils/overlay-link';

export default class Dialog {
  constructor($el, classes = { }) {
    this.$trigger = $el;
    this.$content = this.$trigger.getAttribute('aria-controls');

    this.classes = {
      dialogTemplate: 'dialog-template',
      dialog: 'dialog',
      content: 'dialog__content',
      closeButton: 'dialog__button--close',
      slider: 'dialog-content__slider',
      sliderFrame: 'dialog-content__slider-items',
      sliderItem: 'dialog-content__slider-item',
      ...classes,
    };

    // eslint-disable-next-line prefer-destructuring
    this.dialogId = window.location.hash.split('#')[1];

    this.initDialog();
  }

  initDialog() {
    // Get outer dialog template
    const $template = document.querySelector(`.${this.classes.dialogTemplate}`);

    this.$dialogElement = $template && $template.content
      ? document.importNode($template.content, true).querySelector(`.${this.classes.dialog}`)
      : document.querySelector(`.${this.classes.dialog}`);

    // Close button
    this.$dialogElement.querySelector(`.${this.classes.closeButton}`)
      .addEventListener('click', () => this.closeDialog());

    // Get dialog content container
    this.$dialogContent = this.$dialogElement.querySelector(`.${this.classes.content}`);

    // Define focus trap
    this.focusTrap = createFocusTrap(this.$dialogElement, {
      escapeDeactivates: false,
      clickOutsideDeactivates: true,
    });

    // Trigger event
    this.$trigger.addEventListener('click', () => { this.openDialog(this.$content); });

    // Close event on escape
    this.closeOnEscape = (event) => {
      if (event.key === 'Escape') {
        this.closeDialog();
      }
    };

    // Close event on outside click
    this.closeOnOutsideClick = (event) => {
      const $dialog = document.querySelector(`.${this.classes.dialog}`);
      if (event.target === $dialog) {
        this.closeDialog();
      }
    };

    // If URL contains dialog id, open that dialog
    if (this.dialogId && this.dialogId === this.$content) {
      this.openDialogId(this.dialogId);
    }

    window.addEventListener('hashchange', (e) => {
      const targetId = e.newURL.split('#')[1];
      const targetIsDialog = targetId && document.querySelector(`#${targetId}`).classList.contains('dialog-content');

      if (targetId !== undefined && targetIsDialog) {
        this.openDialogId(targetId);
      } else {
        this.closeDialog();
      }
    });
  }

  loadTarget(targetId = false) {
    // Find dialog content container
    const $targetTemplate = targetId
      ? document.querySelector(`#${targetId}`)
      : console.warn('Missing target content template'); // eslint-disable-line
    const $targetContent = $targetTemplate
      ? document.importNode($targetTemplate, true)
      : false;

    // Insert target content into dialog
    if ($targetContent) {
      this.$dialogContent.innerHTML = '';
      $targetContent.removeAttribute('hidden');
      this.$dialogContent.appendChild($targetContent);
    }

    // Initialize slider if markup exists
    const isSlider = this.$dialogContent.querySelector(`.${this.classes.slider}`);

    if (isSlider) {
      this.slider = new Slider(this.$dialogContent, {
        id: `${targetId}-slider`,
        sliderFrame: this.classes.sliderFrame,
        sliderItem: this.classes.sliderItem,
      });
    }
  }

  openDialog(targetId) {
    const nCurrentDialogs = document.querySelectorAll('.dialog').length;

    if (!nCurrentDialogs) {
      this.loadTarget(targetId);
      document.body.appendChild(this.$dialogElement);
      document.addEventListener('keyup', this.closeOnEscape);
      disableBodyScroll(this.$dialogElement);
      this.focusTrap.activate();

      // Add outside click event
      this.$dialogElement.addEventListener('click', this.closeOnOutsideClick);

      // Add overlay-link to elements inside the dialog
      this.$dialogElement.querySelectorAll('.js-overlay-link').forEach(($link) => {
        addOverlayLink($link);
      });

      // Jump to defined slide
      if (this.$trigger.dataset.slide) {
        location.hash = this.$trigger.dataset.slide; // eslint-disable-line
      }
    }
  }

  closeDialog() {
    const nCurrentDialogs = document.querySelectorAll('.dialog').length;

    if (nCurrentDialogs) {
      // Remove outside click event
      this.$dialogElement.removeEventListener('click', this.closeOnOutsideClick);

      document.body.removeChild(this.$dialogElement);
      document.removeEventListener('keyup', this.closeOnEscape);
      enableBodyScroll(this.$dialogElement);
      this.focusTrap.deactivate();
      this.$dialogContent.innerHTML = '';

      // Clear current URL hashes
      const currentDialogId = window.location.hash.split('#')[1];
      if (this.$trigger.dataset.slide || currentDialogId) {
        history.pushState({}, document.title, window.location.pathname); // eslint-disable-line
      }
    }
  }

  openDialogId(currentDialogId) {
    this.openDialog(currentDialogId);
  }
}

// Initialize all dialogs and triggers
document.querySelectorAll('.js-dialog-trigger').forEach(
  $dialogTrigger => new Dialog($dialogTrigger),
);
