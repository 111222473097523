export default class NavigationDesktop {
  constructor($el) {
    this.$el = $el;
    this.$mainLinks = this.$el.querySelectorAll('.navigation__link--main[aria-controls]');
    this.timer = null;
    this.$currentLink = null;

    this.options = {
      submenuClass: 'navigation__level',
      submenuOpenClass: 'navigation__level--open',
      mainItemClass: 'navigation__item--main',
      mainLinkClass: 'navigation__link--main',
      mainLinkActiveClass: 'navigation__link--main-active',
      mainLinkHoverTimeout: 250,
    };
  }

  init() {
    this.$mainLinks.forEach(($link) => {
      $link.parentNode.addEventListener('mouseenter', this.enter);
      $link.parentNode.addEventListener('mouseleave', this.leave);
      $link.addEventListener('focus', this.focus);
    });
    this.$el.addEventListener('mouseleave', this.leave);
  }

  deInit() {
    this.$mainLinks.forEach(($link) => {
      $link.parentNode.removeEventListener('mouseenter', this.enter);
      $link.parentNode.removeEventListener('mouseleave', this.leave);
      $link.removeEventListener('focus', this.focus);
    });
  }

  enter = (event) => {
    const $target = event.currentTarget;
    const $targetLink = $target.querySelector(`.${this.options.mainLinkClass}`);
    this.$currentLink = $targetLink;
    clearTimeout(this.timer);
    if ($targetLink) {
      $targetLink.setAttribute('aria-expanded', 'true');
      const controls = $targetLink.getAttribute('aria-controls');
      const $submenu = document.getElementById(controls);
      this.closeSubMenus();
      if ($submenu) {
        this.timer = setTimeout(() => {
          $submenu.classList.add(this.options.submenuOpenClass);
          $targetLink.classList.add(this.options.mainLinkActiveClass);
        }, this.options.mainLinkHoverTimeout);
      }
    }
  }

  leave = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.$el
        .querySelectorAll(`.${this.options.submenuOpenClass}`)
        .forEach(($sub) => {
          $sub.classList.remove(this.options.submenuOpenClass);

          if (this.$currentLink) {
            this.$currentLink.classList.remove(this.options.mainLinkActiveClass);
            this.$currentLink.setAttribute('aria-expanded', 'false');
          }
        });
    }, this.options.mainLinkHoverTimeout);
  };

  focus = (event) => {
    const $target = event.currentTarget;

    this.$el
      .querySelectorAll(`.${this.options.submenuOpenClass}`)
      .forEach(($sub) => {
        $sub.classList.remove(this.options.submenuOpenClass);
      });

    if ($target) {
      const $submenu = $target
        .closest(`.${this.options.mainItemClass}`)
        .querySelector(`.${this.options.submenuClass}`);

      if ($submenu) {
        $submenu.classList.add(this.options.submenuOpenClass);
      }
    }
  }

  closeSubMenus() {
    const openMenues = Array.from(this.$el.querySelectorAll(`.${this.options.submenuOpenClass}`));
    const openMenuLinks = openMenues.map(m => m.previousElementSibling);

    openMenues.forEach($menu => $menu.classList.remove(this.options.submenuOpenClass));
    openMenuLinks.forEach(($link) => {
      $link.classList.remove(this.options.mainLinkActiveClass);
      $link.setAttribute('aria-expanded', 'false');
    });
  }
}
