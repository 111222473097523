export default class FilterList {
  constructor($element, options = {}) {
    this.$element = $element;

    this.options = {
      classes: {
        filter: 'filter',
        filterListSingle: 'filter-list--single',
        resultsNumber: 'filter-list__results-number',
        filterCheckbox: 'filter-checkbox__checkbox-input',
        gridItem: 'grid__item--filter-list',
        gridItemVisible: 'grid__item--visible',
        gridItemInvisible: 'grid__item--invisible',
      },
      ...options,
    };

    this.init();
  }

  init() {
    this.$filter = this.$element.querySelector(`.${this.options.classes.filter}`);
    this.$filter.addEventListener('reset', this.filterReset.bind(this));
    this.singleSelectMode = this.$element.classList.contains(`${this.options.classes.filterListSingle}`);
    this.$resultsNumber = this.$element.querySelector(`.${this.options.classes.resultsNumber}`);
    this.$filterCheckboxes = this.$element.querySelectorAll(`.${this.options.classes.filterCheckbox}`);
    this.$gridItems = this.$element.querySelectorAll(`.${this.options.classes.gridItem}`);

    window.addEventListener('load', () => {
      // Perform an initial count and display the no. results
      this.countResults();
      // Init the filter checkboxes
      this.initFilter();
      // Initial call to filterCards to take preselected filter into account
      this.filterCards();
    });
  }

  countResults() {
    // Count grid items that have the modifier .grid__item--visible
    this.$cardsVisible = this.$element.querySelectorAll(`.${this.options.classes.gridItemVisible}`);
    this.$resultsNumber.innerHTML = this.$cardsVisible.length;
  }

  initFilter() {
    // Attach filterCards() to each filter checkbox change event
    this.$filterCheckboxes.forEach(($filterCheckbox) => {
      $filterCheckbox.addEventListener('change', () => {
        this.filterCards($filterCheckbox.id);
      });
    });
  }

  filterCards(filterCheckboxId) {
    const branches = [];
    const media = [];

    // In single-select-mode make sure to uncheck all other checkboxes except the one
    // that has received the change-event
    if (filterCheckboxId && this.singleSelectMode) {
      this.$filterCheckboxes.forEach(($filterCheckbox) => {
        if ($filterCheckbox.id !== filterCheckboxId) {
          // eslint-disable-next-line no-param-reassign
          $filterCheckbox.checked = false;
        }
      });
    }

    // Find checked filter checkboxes and put them in two Array's
    this.$filterCheckboxes.forEach(($filterCheckbox) => {
      if ($filterCheckbox.checked) {
        const dataAttr = $filterCheckbox.closest('.filter-checkbox').dataset;
        if (dataAttr.branch) {
          branches.push(dataAttr.branch.toLowerCase());
        }

        if (dataAttr.medium) {
          media.push(dataAttr.medium.toLowerCase());
        }
      }
    });

    this.$gridItems.forEach(($item) => {
      // Assuming the Element with the data-attributes is the fist Child of the grid element
      const $cardElement = $item.firstElementChild;
      let hasBranch = [];
      let hasMedia = [];

      // Get branch data attr's from the card element
      if ($cardElement.dataset.branch) {
        const itemBranches = JSON.parse($cardElement.dataset.branch).map(
          element => element.toLowerCase(),
        );

        hasBranch = itemBranches.filter(obj => branches.indexOf(obj) !== -1);
      }

      // Get medium data attr's from the card element
      if ($cardElement.dataset.medium) {
        const itemMedia = JSON.parse($cardElement.dataset.medium).map(
          element => element.toLowerCase(),
        );

        hasMedia = itemMedia.filter(obj => media.indexOf(obj) !== -1);
      }

      // Set item to be invisible initially
      $item.classList.remove(`${this.options.classes.gridItemVisible}`);
      $item.classList.add(`${this.options.classes.gridItemInvisible}`);

      if (branches.length > 0 && media.length > 0) {
        // If both a branch and a media filter selected
        if (hasMedia.length > 0 && hasBranch.length > 0) {
          $item.classList.remove(`${this.options.classes.gridItemInvisible}`);
          $item.classList.add(`${this.options.classes.gridItemVisible}`);
        }
      } else if (branches.length > 0 || media.length > 0) {
        // Else if one of a branch or a media filter selected
        if (hasMedia.length > 0 || hasBranch.length > 0) {
          $item.classList.remove(`${this.options.classes.gridItemInvisible}`);
          $item.classList.add(`${this.options.classes.gridItemVisible}`);
        }
      } else {
        // Make item visible
        $item.classList.remove(`${this.options.classes.gridItemInvisible}`);
        $item.classList.add(`${this.options.classes.gridItemVisible}`);
      }
    });

    this.countResults();
  }

  filterReset() {
    // Form checkboxes are reset via reset button or checkboxes,
    // here cards are made visible again and cards are recounted
    this.$gridItems.forEach(($item) => {
      $item.classList.add(`${this.options.classes.gridItemVisible}`);
      $item.classList.remove(`${this.options.classes.gridItemInvisible}`);
    });

    this.countResults();
  }
}

document.querySelectorAll('.js-filter-list').forEach(
  $filterList => new FilterList($filterList),
);
